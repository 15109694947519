import { ensureError } from '@orus.eu/error'
import { useEnqueueToast } from '@orus.eu/pharaoh'
import { useCallback } from 'react'

export async function copyToClipboard(content: string): Promise<void> {
  // store in a global variable as a way for end to end tests to retrieve the value
  // Note : it should be possible to manipulate the actual clipboard, but I didn't manage to
  // get it working, and it seems quite complicated (see https://github.com/puppeteer/puppeteer/issues/7888)
  ;(window as { orusTestClipboardContent?: string }).orusTestClipboardContent = content

  await navigator.clipboard.writeText(content)
}

export function useCopyToClipboard(): { copyToClipboard: (content: string) => void } {
  const { enqueueToast } = useEnqueueToast()

  const doCopyToClipboard = useCallback(
    (content: string) => {
      copyToClipboard(content).catch((err: unknown) => {
        const error = ensureError(err)

        enqueueToast("Impossible d'accéder au presse-papier", { variant: 'danger' })
        console.error(error)
      })
    },
    [enqueueToast],
  )

  return { copyToClipboard: doCopyToClipboard }
}
